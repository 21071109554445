<template>
  <div class="container my-2">
      <b-card title="Student Roster" class="text-center">
        <StudentsTable>
        </StudentsTable>
      </b-card>
     
  </div>
</template>

<script>
import StudentsTable from "../components/StudentsTable";

export default {
  name: "Students",
  components: {
    StudentsTable,
  }
}
</script>