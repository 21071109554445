<template>
  <div class="container">
    
      <table class="table table-hover table-sm text-left">
      <thead>
          <tr>
          <th scope="col">Name</th>
          <th scope="col">Family</th>
          <th scope="col">Price</th>
          <th scope="col">Discount</th>
          <th scope="col">Length</th>
          <th scope="col">Credit</th>
          <th scope="col">Owed</th>
          <th scope="col">Last Lesson</th>
          <th scope="col">
              <button class="btn btn-success btn-sm py-1 float-right text-white" @click="newStudent()">
              <font-awesome-icon icon="plus"/>
              New Student
              </button>

              <button class="btn btn-primary btn-sm py-1 float-right text-white mx-1" @click="toggleInactive()">
                <font-awesome-icon v-if="show_inactive" icon="user" fixed-width/>
                <font-awesome-icon v-else icon="user-slash" fixed-width/>
                {{show_inactive ? 'All Students' : 'Active Only'}}
              </button>

          </th>
          </tr>
      </thead>

      
      <tbody v-if="is_loaded">
        <tr v-for="student in sorted_students" :key="student.id" v-bind:class="student.family ? 'table-light' : ''">
          <td v-if="student.family">{{ student.first_name }}</td>
          <td v-else>{{ student.name }}</td>
          <td v-if="student.family">{{ student.family.name }}</td>
          <td v-else></td>
          <td v-if="student.lesson_price != null">${{ student.lesson_price / 100 }}</td>
          <td v-else></td>
          <td v-if="student.discount">{{ student.discount.name }}</td>
          <td v-else></td>
          <td v-if="student.default_length">{{ student.default_length.duration }} min</td>
          <td v-else></td>
          <td v-if="student.balance > 0" class="text-success">${{ student.balance / 100 }}</td>
          <td v-else></td>
          <td v-if="student.balance < 0" class="text-danger">${{ student.balance / -100 }}</td>
          <td v-else></td>
          <td v-if="student.latest_lesson">{{ student.latest_lesson.week_of | moment("from") }}</td>
          <td v-else>Never</td>
          <td scope="row">
              <button class="btn btn-primary btn-sm py-0 text-white float-right" @click="editStudent(student.id)">
                <font-awesome-icon icon="cog"/> Edit
              </button>
              <b-button v-bind:variant="student.is_active ? 'warning' : 'success'" size="sm" class="py-0 mx-1 text-white float-right" @click="toggleStudentStatus(student.id, !student.is_active)">
                <font-awesome-icon v-if="student.is_active" icon="user-slash" fixed-width/>
                <font-awesome-icon v-else icon="user" fixed-width/>
                {{student.is_active ? 'Mark Inactive' : 'Mark Active'}}
              </b-button>
              
              
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="8">
            <div class="alert alert-light" role="alert" v-if="show_inactive && is_loaded">
              No active or inactive students were found.
              <br>
              Press the green <font-awesome-icon icon="plus"/> button to create a new student. 
            </div>
            <div class="alert alert-light" role="alert" v-else-if="is_loaded">
              No active students were found.
              <br>
              TIP: Press the <font-awesome-icon icon="user"/> button to show both active and inactive students. 
              
            </div>
            <div v-else>
            </div>
          </td>
        </tr>
      </tbody>
      </table>
    <b-modal ref="studentModal" id="student-modal" v-bind:title="studentForm.edit_mode ? 'Edit Student' : 'New Student'" hide-footer>
      <b-form @submit="submitStudent" @reset="resetStudent" class="w-100">
        <b-input-group id="form-name-group" prepend="Name" label-for="form-first-name" class="my-2">
          <b-form-input id="form-first-name" v-model="studentForm.first_name" placeholder="First" required>
          </b-form-input>
          <b-form-input id="form-last-name" v-model="studentForm.last_name" placeholder="Last">
          </b-form-input>
        </b-input-group>

        <!-- Family -->
        <b-input-group id="form-family-group" prepend="Family" label-for="form-family" class="my-2">
          <b-form-select id="form-family" v-model="studentForm.family"  :options="families">
          </b-form-select>
        </b-input-group>

        <div v-if="studentForm.family == 0">
          <b-form-checkbox id="form-use-last-name" v-model="studentForm.use_last_name">
            Use Last Name as new family name
          </b-form-checkbox>
          <b-input-group id="form-new-family-group" prepend="Family Name" label-for="form-new-family" class="my-2">
            <!-- eslint-disable vue/valid-v-model -->
            <b-form-input id="form-new-family" v-model="studentForm.use_last_name ? studentForm.last_name : studentForm.new_family_name" v-bind:disabled="studentForm.use_last_name">
            </b-form-input>
            <!-- eslint-enable -->
          </b-input-group>
        </div>

        <!-- Lesson Length-->
        <b-input-group id="form-family-group" prepend="Lesson Length" label-for="form-length" class="my-2">
          <b-form-select id="form-family" v-model="studentForm.length"  :options="lengths" required>
          </b-form-select>
        </b-input-group>

        
        <!-- Discount-->
        <b-input-group id="form-discount-group" prepend="Discount" label-for="form-discount" class="my-2">
          <b-form-select id="form-discount" v-model="studentForm.discount" :options="discounts">
          </b-form-select>
        </b-input-group>


        <!-- Notes-->
        <b-input-group id="form-notes-group" prepend="Notes" label-for="form-notes" class="my-2">
          <b-form-textarea id="form-notes" v-model="studentForm.notes" >
          </b-form-textarea>
        </b-input-group>
        
        <hr>
        <b-button type="submit" v-bind:variant="studentForm.edit_mode ? 'primary' : 'success'">
          {{studentForm.edit_mode ? 'Save' : 'Create'}}
        </b-button>
        <b-button type="reset" variant="danger" class="mx-1">Cancel</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ApiFactory } from "../api/ApiFactory";
const StudentAPI = ApiFactory.get("students");
const DiscountAPI = ApiFactory.get("discount");
const LessonLengthAPI = ApiFactory.get("lesson_length");
const FamilyAPI = ApiFactory.get("family");

var _ = require('lodash');

export default {
  data() {
    return {
      is_loaded: false,
      show_inactive: false,
      sort_by: 'last_name',
      student_ids: [],
      students: [],
      discounts: [],
      families: [],
      lengths: [],
      studentForm: {
        edit_mode: false,
        new_family_name: null,
        use_last_name: true,
        family: null,
        discount: null,
        length: null,
        first_name: null,
        last_name: null,
        notes: null,
        id: 0,
      }
    }
  },

  computed: {
    sorted_students() {
      return _.orderBy(this.students, [this.sort_by, 'last_name', 'first_name', 'id']); 
    }
  },

  methods: {
    async getStudents(){
      const {data} = await StudentAPI.getList(this.show_inactive);
      this.student_ids = data;
      this.students = [];
      this.is_loaded = false;
      for (var i=0; i < this.student_ids.length; i++){
        StudentAPI.getStudent(this.student_ids[i]).then((response) =>{
            this.students.push(response.data);
            if (this.students.length == this.student_ids.length){
                this.is_loaded = true;
            }
        });
      }
    },

    newStudent(){
      this.initForm(true);
      this.$refs.studentModal.show();
    },

    async editStudent(id){
      const {data} = await StudentAPI.getStudent(id);

      this.initForm(true);
      this.studentForm.edit_mode = true;
      this.studentForm.first_name = data.first_name;
      this.studentForm.last_name = data.last_name;
      this.studentForm.id = id;
      this.studentForm.length = data.default_len_id 
      this.studentForm.discount = data.discount_id
      this.studentForm.family = data.family_id
      this.$refs.studentModal.show();
    },

    async submitStudent(evt){
      evt.preventDefault()
      this.$refs.studentModal.hide();

      const payload = {
        first_name: this.studentForm.first_name,
        last_name: this.studentForm.last_name,
        default_len_id: this.studentForm.length,
        discount_id: this.studentForm.discount,
        //family_id: this.studentForm.family,
      };

      if (this.studentForm.family == 0){
        payload.family = {
          name: this.studentForm.use_last_name ? this.studentForm.last_name : this.studentForm.new_family_name
        }
      }else{
        payload.family_id = this.studentForm.family
      }
      if (this.studentForm.edit_mode){
        payload.id = this.studentForm.id;
        await StudentAPI.updateStudent(payload);
        this.getStudents();
        this.initForm(true)
      }else{
        const {data} = await StudentAPI.createStudent(payload);
        this.students.push(data);
        this.initForm(true)
      }
    },

    resetStudent(){
      this.initForm();
      this.$refs.studentModal.hide();
    },

    async initForm(reset=false){
      this.studentForm.edit_mode = false;
      this.studentForm.first_name = "";
      this.studentForm.last_name = "";
      this.studentForm.notes = "";
      this.studentForm.family = null;
      this.studentForm.discount = null;
      this.studentForm.length = null;

      if (this.discounts.length == 0){
        const {data} = await DiscountAPI.get();
        this.discounts = [{value: null, text: "--No Discount--" }];
        for (var i=0; i < data.length; i++){
          this.discounts.push({value: data[i].id, text: data[i].name });
        }
      }
      if (this.lengths.length == 0){
        const {data} = await LessonLengthAPI.get();
        this.lengths = [];
        for (i=0; i < data.length; i++){
          this.lengths.push({value: data[i].id, text: data[i].duration + "m"});
        }
      }
      if (this.families.length == 0 || reset){
        this.families = []
        const {data} = await FamilyAPI.get_all();
        this.families = [{value: null, text: "--No Family--" }];
        if (!this.studentForm.edit_mode){
          this.families.push({value: 0, text: "--Create New Family--" })
        }
        for (i=0; i < data.length; i++){
          this.families.push({value: data[i].id, text: data[i].name});
        }
      }
    },

    async toggleStudentStatus(id, new_status){
      var payload = {
        id: id,
        is_active: new_status,
      }
      StudentAPI.updateStudent(payload).then(res => {
        var id_idx = 0;
        for (var idx = 0; idx < this.students.length; idx ++){
          if (this.students[idx].id == id){
            id_idx = idx;
            break;
          }
        }
        this.$set(this.students, id_idx, res.data)
        if (!this.show_inactive && new_status == false){
          this.students.splice(id_idx, 1)
          //console.log(id_idx)
          //console.log(id)
          
        }
      });
    },

    toggleInactive(){
        this.show_inactive = !this.show_inactive
        this.getStudents();
    }

  },

  created: function(){
    this.getStudents();
  },
}
</script>
